import React, { useEffect, createContext, useContext, useState } from 'react'
import Splash from './components/page/Splash'
import Toast from './components/common/Toast'
import Routes from './Routes'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from './redux'
import LocalStorage from './lib/LocalStorage'
import UserRepository from './common/repository/UserRepository'
import Result from './common/repository/Result'
import { setAuth, initializingAuthState } from './redux/auth/actioncreators'
export const GlobalContext = createContext({})


const App = () => {
	const [Count, setCounts] = useState<any>({
		totalCount: 0,
		cancel_case: 0,
		case_close: 0,
		case_start: 0,
		close_investigation: 0,
		leave_applied: 0,
		location_change_request: 0,
		lodging_expenses_request: 0,
		mrd_request: 0,
		outlocation_travel: 0,
	},)

	const userRepo = new UserRepository()
	const authState = useSelector((state: AppState) => state.auth)
	const dispatch = useDispatch()
	const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN)

	/** retrieve user from token */
	useEffect(() => {
		if (token === null || authState.user !== null)
			return

		dispatch(initializingAuthState());

		(async () => {
			const result = await userRepo.getUserByToken()
			if (result instanceof Result.Success) {
				const user = result.data
				dispatch(setAuth(user))
			} else {
				dispatch(setAuth(null))
			}
		})()
	}, [token, authState.user])

	return (
		<>
			{/*<div style={{*/}
			{/*	position: "absolute",*/}
			{/*	top: "0px",*/}
			{/*	right: "10px",*/}
			{/*	color:"white",*/}
			{/*	width:"200px",*/}
			{/*	height:"30px",*/}
			{/*	backgroundColor:"darkred",*/}
			{/*	padding:"4px",*/}
			{/*	opacity:"0.7",*/}
			{/*	fontSize: "14px",*/}
			{/*	textAlign: "center",*/}
			{/*	zIndex: 9999999*/}
			{/*}} > Testing Mode</div>*/}
			<GlobalContext.Provider value={{ Count, setCounts }} >
				<Routes />
			</GlobalContext.Provider>
			<Toast />
		</>
	)

}

export default App
