import React from "react";
import { Form, DropdownItemProps, Grid } from "semantic-ui-react";
import Button from "./../../elements/Button";
import CaseType from "../../../models/CaseType";
import CaseTypeRepository from "../../../common/repository/CaseTypeRepository";
import Result from "../../../common/repository/Result";
import { toast } from "../Toast";
import CaseCategoryRepository from "../../../common/repository/CaseCategoryRepository";
import validator from "../../../common/validator";
import { TagsInput } from "react-tag-input-component";

interface Props {
  //if model is passed that means update
  model?: CaseType;

  onSuccess: (caseType: CaseType) => void;
  onError?: () => void;
}

interface State {
  form_loading: boolean;
  form_caseCategoryOptions: DropdownItemProps[];
  form_caseCategoryLoading: boolean;

  caseType_title: string;
  caseType_category: string;
  caseType_internalTATDays: string;
  caseType_internalTATHours: string;
  caseType_investigatorTATDays: string;
  caseType_investigatorTATHours: string;
  caseType_initialCasePortionCapping: string;
  caseType_Services: any[]
  servicesType: 
  ["HOSPITAL INFRASTRUCTURE", "TREATING DOCTOR", "PATHOLOGIST", "INSURED INFO", "ATTENDANT INFO"]
  CaseType_RequiredDocumnets: []
}

class CaseTypeForm extends React.Component<Props, State> {
  private caseTypeRepo = new CaseTypeRepository();
  private caseCategoryRepo = new CaseCategoryRepository();

  constructor(props: Props) {
    super(props);
    const stateFromModel = this.deriveCaseTypeStateFromModel();
    this.state = {
      form_loading: false,
      form_caseCategoryOptions: [],
      form_caseCategoryLoading: false,
      servicesType: ["HOSPITAL INFRASTRUCTURE", "TREATING DOCTOR", "PATHOLOGIST", "INSURED INFO", "ATTENDANT INFO"],
      ...stateFromModel,
    };
  }

  componentDidMount() {
    this.getCaseCategories();
  }

  private deriveCaseTypeStateFromModel = () => {
    const model = this.props.model;
    return {
      caseType_title: (model && model.getTitle()) || "",
      caseType_category: (model && model.getCaseCategoryCode()) || "",
      caseType_internalTATDays: (model && CaseType.getTATPair(model.getInternalTAT())[0].toString()) || "",
      caseType_internalTATHours: (model && CaseType.getTATPair(model.getInternalTAT())[1].toString()) || "",
      caseType_investigatorTATDays: (model && CaseType.getTATPair(model.getInvestigatorTAT())[0].toString()) || "",
      caseType_investigatorTATHours: (model && CaseType.getTATPair(model.getInvestigatorTAT())[1].toString()) || "",
      caseType_initialCasePortionCapping: (model && model.getInitialCasePortionCapping().toString()) || "",
      caseType_Services: (model && model.getServices()) || [],
      CaseType_RequiredDocumnets: (model && model.getRequiredDocumne()) || [],
    };
  };

  private getCaseCategories = () => {
    this.setState({ form_caseCategoryLoading: true }, async () => {
      const result = await this.caseCategoryRepo.getCategories();
      if (result instanceof Result.Success) {
        const options = result.data.items.map((it) => {
          return {
            text: it.getTitle(),
            value: it.getCode(),
            key: it.getCode(),
          };
        });
        this.setState({
          form_caseCategoryLoading: false,
          form_caseCategoryOptions: options,
        });
      } else {
        this.setState({ form_caseCategoryLoading: false }, () =>
          toast.error("Could not load case categories")
        );
      }
    });
  };

  private onSubmit = () => {
    this.setState({ form_loading: true }, () => {
      if (this.props.model) this.updateCaseType();
      else this.addCaseType();
    });
  };

  private addCaseType = async () => {
    const internalTAT =
      CaseType.toHours(parseInt(this.state.caseType_internalTATDays)) +
      parseInt(this.state.caseType_internalTATHours);
    const investigatorTAT =
      CaseType.toHours(parseInt(this.state.caseType_investigatorTATDays)) +
      parseInt(this.state.caseType_investigatorTATHours);
    let capping = parseFloat(this.state.caseType_initialCasePortionCapping);

    const result = await this.caseTypeRepo.addCaseType({
      name: this.state.caseType_title,
      case_category: this.state.caseType_category,
      internal_TAT: internalTAT,
      investigator_TAT: investigatorTAT,
      capping,
      services: this.state.caseType_Services,
      required_documents: this.state.CaseType_RequiredDocumnets
    });

    if (result instanceof Result.Success) {
      const caseType = result.data;
      this.setState({ form_loading: false }, () => {
        toast.success("Case Type added successfully");
        this.props.onSuccess(caseType);
      });
    } else {
      this.setState({ form_loading: false }, () => {
        const message = result.message || "Something went wrong";
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };

  private updateCaseType = async () => {
    const id = this.props.model!.getId();
    const internalTAT =
      CaseType.toHours(parseInt(this.state.caseType_internalTATDays)) +
      parseInt(this.state.caseType_internalTATHours);
    const investigatorTAT =
      CaseType.toHours(parseInt(this.state.caseType_investigatorTATDays)) +
      parseInt(this.state.caseType_investigatorTATHours);
    let capping = parseFloat(this.state.caseType_initialCasePortionCapping);

    const result = await this.caseTypeRepo.updateCaseType({
      id,
      name: this.state.caseType_title,
      case_category: this.state.caseType_category,
      internal_TAT: internalTAT,
      investigator_TAT: investigatorTAT,
      capping,
      services: this.state.caseType_Services,
      required_documents: this.state.CaseType_RequiredDocumnets
    });

    if (result instanceof Result.Success) {
      const caseType = result.data;
      this.setState({ form_loading: false }, () => {
        toast.success("Case Type updated successfully");
        this.props.onSuccess(caseType);
      });
    } else {
      this.setState({ form_loading: false }, () => {
        const message = result.message || "Something went wrong";
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };

  handleCheckboxChange = (event: any) => {
    let newArray = [...this.state.caseType_Services, event.target.id];
    if (this.state.caseType_Services.includes(event.target.id)) {
      newArray = newArray.filter(day => day !== event.target.id);
    }
    this.setState({
      caseType_Services: newArray
    });
  };

  render(): JSX.Element {
    return (
      <Form loading={this.state.form_loading}>
        {/* name */}
        <Form.Input
          label="Case Type Name"
          placeholder="e.g. Cashless"
          value={this.state.caseType_title}
          onChange={(_, { value }) => this.setState({ caseType_title: value })}
        />
        {/* name */}

        {/* case category */}
        <Form.Dropdown
          selection
          loading={this.state.form_caseCategoryLoading}
          label="Case Category"
          placeholder="Select a category"
          options={this.state.form_caseCategoryOptions}
          value={this.state.caseType_category}
          onChange={(_, { value }) =>
            this.setState({ caseType_category: value as string })
          }
        />
        {/* case category */}

        {/* internal TAT */}
        <Form.Group inine>
          <Form.Input
            label="Internal TAT (Days)"
            placeholder="Enter days"
            value={this.state.caseType_internalTATDays}
            onChange={(_, { value }) => {
              if (validator.integer.isValid(value))
                this.setState({ caseType_internalTATDays: value });
            }}
          />
          <Form.Input
            label="Internal TAT (Hours)"
            placeholder="Enter hours"
            value={this.state.caseType_internalTATHours}
            onChange={(_, { value }) => {
              if (validator.integer.isValid(value))
                this.setState({ caseType_internalTATHours: value });
            }}
          />
        </Form.Group>
        {/* internal TAT */}

        {/* investigator TAT */}
        <Form.Group>
          <Form.Input
            label="Investigator TAT (Days)"
            placeholder="Enter days"
            value={this.state.caseType_investigatorTATDays}
            onChange={(_, { value }) => {
              if (validator.integer.isValid(value))
                this.setState({ caseType_investigatorTATDays: value });
            }}
          />
          <Form.Input
            label="Investigator TAT (Hours)"
            placeholder="Enter hours"
            value={this.state.caseType_investigatorTATHours}
            onChange={(_, { value }) => {
              if (validator.integer.isValid(value))
                this.setState({ caseType_investigatorTATHours: value });
            }}
          />
        </Form.Group>
        {/* investigator TAT */}

        {/* case portion capping */}
        <Form.Input
          label="Initial Case Portion Capping"
          placeholder="Value between 0.00 and 1.00"
          value={this.state.caseType_initialCasePortionCapping}
          onChange={(_, { value }) => {
            const numValue = parseFloat(value);
            if (value.length === 0 || (numValue >= 0 && numValue <= 1))
              this.setState({ caseType_initialCasePortionCapping: value });
          }}
        />
        <label><strong> Services</strong></label>
        <Grid>
          <Grid.Row>
            {this.state.servicesType.map((item, index) => <>
              <Grid.Column width={8}>
                <input
                  className="CaseTypeCheck"
                  key={index}
                  type="checkbox"
                  id={item}
                  value={item}
                  onChange={this.handleCheckboxChange}
                  checked={this.state.caseType_Services.includes(item)}
                />
                <label htmlFor={item}>{item}</label>
              </Grid.Column>
            </>
            )}
          </Grid.Row>
        </Grid>
        <label>Required Documents</label>
        <TagsInput
          value={this.state.CaseType_RequiredDocumnets}
          onChange={(value: any) => this.setState({ CaseType_RequiredDocumnets: value })}
          name="Required Documents"
          placeHolder="PanCard"
        />
        {/* case portion capping */}
        <Button
          positive
          onClick={this.onSubmit}
          disabled={
            this.state.caseType_title.trim().length === 0 ||
            this.state.caseType_category.trim().length === 0 ||
            this.state.caseType_internalTATDays.trim().length === 0 ||
            this.state.caseType_internalTATHours.trim().length === 0 ||
            this.state.caseType_investigatorTATDays.trim().length === 0 ||
            this.state.caseType_investigatorTATHours.trim().length === 0 ||
            this.state.caseType_initialCasePortionCapping.trim().length === 0 ||
            this.state.caseType_Services.length === 0 ||
            this.state.CaseType_RequiredDocumnets.length === 0
          }
        >
          Submit
        </Button>
      </Form>
    );
  }
}

export default CaseTypeForm;
