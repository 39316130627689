
export default {
  apiBaseURLSocket: 'https://amableclaimprobe.com/',
  mrdRecipt: 'https://amableclaimprobe.com/',
  apiBaseURL: "https://amableclaimprobe.com/api",
}

// export default {
//    apiBaseURLSocket: "http://192.168.0.107:3112",
//    mrdRecipt: 'http://192.168.12.70:3014/',
//    apiBaseURL: "http://192.168.0.107:3112/api" ,
// }    
