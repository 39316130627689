import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import constants from "./../../../common/constants";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../redux";
import { toast } from "../../common/Toast";
import Result from "../../../common/repository/Result";
import { Button, Icon, Image, Popup, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import config from "../../../common/repository/config";
import NotifecationRepo from "../../../common/repository/NotifecationRepo";
import NotificationPopup from "./NotificationPopUp";
import { GlobalContext } from "../../../App";
import LocalStorage from "../../../lib/LocalStorage";
import { setAuth } from "../../../redux/auth/actioncreators";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.03);
`;
const Header = (): JSX.Element => {
  const { Count, setCounts } = useContext<any>(GlobalContext);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [notificationError, setNotificationError] = useState<string | null>(null);
  const [missedNotifications, setMissedNotifications] = useState<any[]>([]);
  const [invoiceNotificationData, setInvoiceNotificationData] = useState<any | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [missedInvoice, setMissedInvoice] = useState<any[]>([]);
  const { totalCount } = Count;
  const notifecationRepo = new NotifecationRepo();
  const authState = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();
  const user = authState.user;
  const socket: Socket = io(config.apiBaseURLSocket);
  const location = useLocation();

  let notificationBox: Notification


  useEffect(() => {
    const handleConnect = async () => {
      console.log('Connected to WebSocket server');
      await fetchMissedNotifications();
    };
    const handleDisconnect = () => {
      console.log('Disconnected from WebSocket server');
    };
    const handleNotification = (notify: any) => {
      if (!notify) return;
      console.log('Received notification:', notify);
      if (notify.type === "invoice_change") {
        const notificationData = {
          typeId: notify.type_id || '',
          history_id: notify.history_id || '',
          metadata: notify.metadata || {},
          message: notify.message || '',
          title: notify.title || '',
          type_id: notify.type_id || '',
        };
        setMissedNotifications(prev => [...prev, notificationData]);
        setInvoiceNotificationData(notificationData);
        setShowPopup(true);
      } else {
        console.log("Unhandled notification type:", notify.type);
      }
    };
    if (socket) {
      socket.on("connect", handleConnect);
      socket.on("notification", handleNotification);
      socket.on('disconnect', handleDisconnect);

      if (user) {
        socket.emit("add", { user_id: user.getId() });
      }
      return () => {
        if (socket) {
          socket.off('connect', handleConnect);
          socket.off('disconnect', handleDisconnect);
          socket.off("notification", handleNotification);
        }
      };
    }
  }, [user]);

  useEffect(() => {
    if (missedNotifications.length > 0) {
      setShowPopup(true);
    }
  }, [missedNotifications]);



  useEffect(() => {
    GetAllCount();
  }, []);

  const getNotification = (notify: any) => {
    if (!notify) return null
    console.log('Received notification:', notify);
    //for mrd
    if (notify.type === "mrd_request") {
      setNotifications(prevNotifications => [...prevNotifications, notify]);
      setMissedNotifications([]);
    } else {
      //old notification code
      setCounts((obj: any) => {
        let totalCaseCount = obj.totalCount + 1
        return ({ ...obj, [notify.type]: obj[notify.type] + 1, totalCount: totalCaseCount })
      })
      notificationBox = new Notification(notify.title, { body: notify.message, icon: '', data: notify })
      notificationBox.addEventListener('click', (notificationBox) => {
        setCounts((obj: any) => {
          let totalCaseCount = obj.totalCount - 1
          return ({ ...obj, [notify.type]: obj[notify.type] + 1, totalCount: totalCaseCount })
        })
      })
    }
  }

  async function GetAllCount() {
    const result = await notifecationRepo.getNotifectionAllCount();
    if (result instanceof Result.Success) {
      setCounts((obj: any) => {
        // console.log('obj', ({ ...obj, totalCount: result.data.totalItemCount }));
        return ({ ...obj, totalCount: result.data.totalItemCount })

      })
    } else {
      const message = result.message || "Could not load Count";
      toast.error(message);
    }
  }
  const handleApprove = async (investigatorId: string, caseId: string, typeId: string) => {
    console.log("Notification approved:", investigatorId, caseId, typeId);
    setNotifications(prevNotifications => prevNotifications.slice(1));
    setNotifyData(null);
    setCounts((prevCounts: { totalCount: number; }) => ({
      ...prevCounts,
      totalCount: prevCounts.totalCount - 1,
    }));

    if (notifications.length > 0) {
      setNotifyData(notifications[0]);
    }
  };

  const handleDecline = () => {
    setNotifications(prevNotifications => prevNotifications.slice(1));
    setNotifyData(null);

    if (notifications.length > 0) {
      setNotifyData(notifications[0]);
    }
  };
  const onLogout = () => {
    socket.emit('logout', { user_id: user?.getId() });
    LocalStorage.remove(LocalStorage.KEY_AUTH_TOKEN);
    localStorage.removeItem("caseCounts");
    dispatch(setAuth(null));
  };

  const fetchMissedNotifications = async () => {
    if (location.pathname.startsWith('/case/view')) {
      console.log("fetched missed Notifications");
      return;
    }
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        console.error('Authentication token is missing.');
        return;
      }
      const response = await fetch(`${config.apiBaseURL}/notification/missedInvoiceChanges`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      console.log(`missedData: `, data);
      setMissedInvoice(data.data.items || []);
      setMissedNotifications(data.data.items || []);
    } catch (error) {
      setNotificationError(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };
  useEffect(() => {
    if (missedNotifications.length > 0) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [missedNotifications]);
  return (
    <>
      {/* <div className="notification-popups">
        {showPopup && invoiceNotificationData && (
          <NotificationPopup
            notificationData={invoiceNotificationData}
          />
        )}
      </div>` */}
      <div className="notification-popups">
        {user?.getRole().getTitle() === constants.roleTitle.ao && (
          missedNotifications.length > 0 ? (
            missedNotifications.map((notification, index) => (
              notification && notification.title ? (
                <NotificationPopup
                  key={`missed-${index}`}
                  notificationData={notification}
                />
              ) : null
            ))
          ) : (
            notifications.map((notification, index) => (
              notification && notification.title ? (
                <NotificationPopup
                  key={`notif-${index}`}
                  notificationData={notification}
                />
              ) : null
            ))
          )
        )}
      </div> 

      <Container style={{ backgroundColor: "#FF6600", color: "white"}}>
        <div style={{ display: "flex", alignItems: "center", margin: "0 16px" }}>
          {/* Logo on the left side */}
          <div style={{ marginRight: '16px' }}>
            <img
              src={`${process.env.PUBLIC_URL}/mumbaiwebLogo.png`}
              alt="Logo"
              style={{ height: "50px", width: "auto" }}
            />
          </div>
        </div>

        <div style={{ flex: 1, textAlign: "center", }}>
          <h1 style={{ color: "white", margin: "0px", fontSize: "32px" }}>Verifacto Web</h1>
        </div>

        <div style={{ width: `${constants.sidebarWidth}px`  }}>
          <strong style={{ fontSize: "20px"}}>{user && user.getName()}</strong>
          <br />
          {user && user.getRole().getTitle()}
        </div>

        <div style={{ margin: "0 16px" }}>
          {(user?.getRole().getTitle() === constants.roleTitle.admin ||
            user?.getRole().getTitle() === constants.roleTitle.ca) && (
              <>
                {totalCount > 0 && (
                  <span
                    style={{
                      position: "relative",
                      right: "-18px",
                      background: "red",
                      padding: "5px 10px",
                      borderRadius: "50%",
                    }}
                  >
                    {totalCount <= 1000 ? totalCount : "1000+"}
                  </span>
                )}
                <Popup
                  basic
                  on="click"
                  trigger={
                    <Icon name="bell" size="big" style={{ fontSize: "3em", cursor: "pointer" }} />
                  }
                >
                  <Segment.Group>
                    <Segment>
                      <Link to="/notification">Notifications</Link>
                    </Segment>
                  </Segment.Group>
                </Popup>{" "}
              </>
            )}
          <Popup
            basic
            on="click"
            trigger={
              <Image
                avatar
                src={user && user.getProfilePicURL()}
                style={{ height: "4em", width: "4em" }}
              />
            }
          >
            <Segment.Group>


              <Segment>
                <Link to="/profile">Update Profile</Link>
              </Segment>
              <Segment>
                <Button color="red" onClick={onLogout}>
                  Logout
                </Button>
              </Segment>
            </Segment.Group>
          </Popup>
        </div>
      </Container>
    </>
  );
};

export default Header;
function setNotifyData(arg0: null) {
  // throw new Error("Function not implemented.");
}



