import React, { useState, useEffect } from "react";
import { Form, Dropdown, Popup, Icon } from "semantic-ui-react";
import CaseType from "../../../models/CaseType";
import CaseCategory from "../../../models/CaseCategory";
import CaseTypeRepository from "../../../common/repository/CaseTypeRepository";
import CaseCategoryRepository from "../../../common/repository/CaseCategoryRepository";
import Result from "../../../common/repository/Result";
import FormLabel from "../../common/FormLabel";

interface Props {
  value: CaseFormInputTypeAndCategoryValue;
  onChange(value: CaseFormInputTypeAndCategoryValue): void;
  onCaseTypeChange?(value: CaseType): void;
  onCaseCategoryChange?(value: CaseCategory): void;
  labelWidth?: string;
  name?:string;
}

export interface CaseFormInputTypeAndCategoryValue {
  caseCategory: string;
  caseType: string;
}

const CaseFormInputTypeAndCategory = (props: Props) => {
  const caseTypeRepo = new CaseTypeRepository();
  const caseCategoryRepo = new CaseCategoryRepository();

  const {
    value,
    onChange,
    onCaseTypeChange,
    onCaseCategoryChange,
    labelWidth,
  } = props;

  /** states for component */
  const [loadingCaseTypes, setLoadingCaseTypes] = useState(false);
  const [loadingCaseCategories, setLoadingCaseCategories] = useState(false);
  const [caseTypes, setCaseTypes] = useState<CaseType[]>([]);
  const [caseCategories, setCaseCategories] = useState<CaseCategory[]>([]);

  /** effects */
  useEffect(() => {
    setLoadingCaseTypes(true);
    (async () => {
      const result = await caseTypeRepo.getCaseTypes();
      if (result instanceof Result.Success) setCaseTypes(result.data.items);
      setLoadingCaseTypes(false);
    })();
  }, []);

  useEffect(() => {
    setLoadingCaseCategories(true);
    (async () => {
      const result = await caseCategoryRepo.getCategories();
      if (result instanceof Result.Success)
        setCaseCategories(result.data.items);
      setLoadingCaseCategories(false);
    })();
  }, []);

  /** helper functions */
  const caseTypesToOptions = () =>
    caseTypes.map((it) => {
      return { text: it.getTitle(), value: it.getId(), key: it.getId() };
    });
  const caseCategotriesToOptions = () =>
    caseCategories.map((it) => {
      return { text: it.getTitle(), value: it.getCode(), key: it.getId() };
    });
  const findCaseTypeById = (id: string) =>
    caseTypes.find((it) => it.getId() === id);
  const findCaseCategoryByCode = (code: string) =>
    caseCategories.find((it) => it.getCode() === code);

  let internalValue = { ...value };

  const internalOnChange = <T extends keyof CaseFormInputTypeAndCategoryValue>(
    key: T,
    value: string,
    accumulate: boolean = false
  ) => {
    if (accumulate) {
      internalValue = { ...internalValue, [key]: value };
      return;
    }
    const newValue = { ...internalValue, [key]: value };
    onChange(newValue);
  };

  const width = labelWidth || "100px";
  return (
    <>
      {/* case type input */}
      <Form.Group inline>
        <Form.Field required>
          <FormLabel width={width}>Case Type</FormLabel>
          <Dropdown
            search
            selection
            placeholder="Select Case Type"
            loading={loadingCaseTypes}
            value={internalValue.caseType}
            options={caseTypesToOptions()}
            onChange={(_, { value }) => {
              const caseType = findCaseTypeById(value as string);
              if (caseType === undefined) return;

              internalOnChange("caseType", value as string, true);
              if (onCaseTypeChange) onCaseTypeChange(caseType);

              const caseCategory = findCaseCategoryByCode(
                caseType.getCaseCategoryCode()
              );
              if (caseCategory === undefined) return;

              internalOnChange("caseCategory", caseCategory.getCode());
              if (onCaseCategoryChange) onCaseCategoryChange(caseCategory);
            }}
          />
        </Form.Field>
      </Form.Group>
      {/* case type input */}

      {/* case category input */}
      <Form.Group inline>
        <Form.Field required>
          <FormLabel width={width}>Case Category</FormLabel>
          <Dropdown
            disabled
            selection
            placeholder="Select Category"
            loading={loadingCaseCategories}
            value={internalValue.caseCategory}
            options={caseCategotriesToOptions()}
            onChange={(_, { value }) => {
              internalOnChange("caseCategory", value as string);
              const caseCategory = findCaseCategoryByCode(value as string);
              if (caseCategory === undefined) return;

              if (onCaseCategoryChange) onCaseCategoryChange(caseCategory);
            }}
          />
        </Form.Field>
        <Form.Field>
          <Popup
            position="top center"
            inverted
            trigger={<Icon name="warning sign" color="orange" />}
          >
            <p>Case Category is automatically set from Case Type</p>
          </Popup>
        </Form.Field>
      </Form.Group>
      {/* case category input */}
    </>
  );
};

/** initial value to be used when not provided any  */
CaseFormInputTypeAndCategory.initialValue = {
  caseType: "",
  caseCategory: "",
} as CaseFormInputTypeAndCategoryValue;

export default CaseFormInputTypeAndCategory;
