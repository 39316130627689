export default class URLResource {
  private name: string
  private extension: string
  

  constructor(private url: string) {
    this.name = this.parseName(url)
    this.extension = this.parseExtension(url)
  }

  getURL = () => this.url
  getName = () => this.name
  getFullName = () => this.name + '.' + this.extension
  getExtension = () => this.extension

  private parseName = (url: string) => {
    // const b = url?.lastIndexOf('/')

    // if (b === -1) return ''

    // const e = url?.lastIndexOf('__')
    // if (e === -1) return ''

    // return url?.slice(b + 1, e)

    let splitUrlArr=url?.split('/')

    if(splitUrlArr?.length)return splitUrlArr[splitUrlArr?.length-1]
    else return ""
  }

  private parseExtension = (url: string) => {
    const i = url?.lastIndexOf('.')
    if (i === 1) return ''
    return url?.slice(i + 1, url.length)
  }
}
